<!--
 * @Description: 品质管理--配置类目--新增表单页面
 * @Author: 马俊
 * @Date: 2021-04-26 18:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="categoryForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="config"
      :submitUrl="submitUrl"
      :form="form"
      @update="update"
    >
      <col2-detail>
        <col2-block title="类目信息">
          <el-form-item
            label="类目名称"
            prop="categoryName"
            :rules="[{required: true, message: '请输入类目名称', trigger: 'blur'}]"
          >
            <v-input v-model="form.categoryName" placeholder="请输入类目名称" />
          </el-form-item>
          <el-form-item
            label="类目级别"
            prop="categoryLevel"
            :rules="[{required: true, message: '请选择类目级别', trigger: 'change'}]"
          >
            <span v-if="isEdit">{{categoryLevelMap[form.categoryLevel]}}</span>
            <v-select v-else v-model="form.categoryLevel" :options="categoryLevelOps" @change="changeLevel"/>
          </el-form-item>
          <el-form-item
            label="类目编码"
            prop="categoryCode"
            :rules="[{required: true, validator: validCode, trigger: 'blur'}]"
          >
            <v-input v-model="form.categoryCode" :maxlength="10" placeholder="请输入类目编码" />
          </el-form-item>
          <!-- 当选择的级别不为一级时，暂时上级类目控件 -->
          <el-form-item
            v-if="form.categoryLevel !== 1"
            label="上级类目"
            prop="parentId"
            :rules="[{required: true, message: '请选择上级类目', trigger: 'change'}]"
          >
            <span v-if="isEdit">{{parentName || '--'}}</span>
            <v-select v-else v-model="form.parentId" filterable :options="prevCategoryOps" />
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <v-textarea v-model="form.remark" :maxlength="200" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import {
  getQualityCheckCategoryInfoByIdURL,
  getCheckCategoryListSelectURL,
  addQualityCheckCategoryURL,
  updateQualityCheckCategoryURL
} from './api'
import {
  categoryLevelOps,
  categoryLevelMap
} from './map'
import {
  Col2Detail,
  Col2Block
} from 'components/bussiness'
import { mapHelper } from 'common/utils'
// import { createHTMLVNode } from 'common/vdom'

export default {
  name: 'categoryForm',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      // width: 182,
      config: {
        queryUrl: getQualityCheckCategoryInfoByIdURL
      },
      categoryLevelOps: categoryLevelOps(2),
      categoryLevelMap,
      prevCategoryList: [],
      parentName: '',
      form: {
        categoryName: '',
        categoryLevel: undefined,
        categoryCode: '',
        parentId: undefined,
        remark: ''
      },
      onOff: true,
      isAddChild: false,
    }
  },
  mounted () {
    const { id, parentId } = this.$route.query
    this.onOff = false
    if (id !== undefined) {
      this.onOff = true
      this.$refs.formPanel.getData({ id })
    }
    if (parentId !== undefined) {
      this.isAddChild = true
      this.form.parentId = parentId
      this.$refs.formPanel.getData({ id: parentId })
    }
    this.$setBreadcrumb(`${id ? '编辑' : '新增'}类目`)
  },

  computed: {
    isEdit () {
      return this.$route.query.id !== undefined || this.isAddChild
    },
    submitUrl () {
      return this.$route.query.id !== undefined ? updateQualityCheckCategoryURL : addQualityCheckCategoryURL
    },
    prevCategoryOps () {
      return this.levelCategoryToOps(this.prevCategoryList)
    }
  },

  methods: {
    update (data) {
      if (this.isAddChild) {
        const { categoryName: parentName, categoryLevel: parentLevel } = data
        this.parentName = parentName
        this.form.categoryLevel = +parentLevel + 1
        return
      }
      this.parentName = data.parentName
      Object.keys({ ...this.form, id: '' }).forEach(key => {
        this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
      })
      this.$nextTick(() => {
        this.onOff = false
      })
    },
    changeLevel (level) {
      if (!this.onOff) { // 编辑获取详情时,不触发清空
        this.form.parentId = undefined
        this.prevCategoryList = []
      }
      if (!level || level === 1) { return }
      this.getPrevCategoryList(level - 1)
    },
    // 获取上一级类目列表
    getPrevCategoryList (level) {
      this.$axios.get(getCheckCategoryListSelectURL, {
        params: {
          categoryLevel: level,
          curPage: 1,
          pageSize: 100000
        }
      }).then((res) => {
        if (res.status === 100) {
          if (res.data && res.data.length) {
            this.prevCategoryList = res.data
          }
        }
      })
    },

    // 一级、二级、三级类目数据格式转换
    levelCategoryToOps (arr) {
      let list = []
      arr.forEach(item => {
        list.push({
          text: item.categoryName,
          value: item.id
        })
      })
      const { setOps } = mapHelper.setMap(list)
      return list.length ? setOps(2) : list
    },
    validCode (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入类目编码'))
      } else if (!/^[a-zA-Z]{1,10}$/.test(value)) {
        callback(new Error('请输入不超过10位的字母组合'))
      } else {
        callback()
      }
    },

    getEmptyValue (val, emptyVal) { // 对空值进行判断
      if (val === undefined || val === '' || val === null) {
        return emptyVal
      } else if (val === 0 || val === '0') {
        return 0
      } else if (val === false) {
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.categoryForm-wrapper {
}
</style>
