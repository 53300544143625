var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "categoryForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { submitUrl: _vm.submitUrl, form: _vm.form },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.config,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "类目信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "类目名称",
                        prop: "categoryName",
                        rules: [
                          {
                            required: true,
                            message: "请输入类目名称",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { placeholder: "请输入类目名称" },
                        model: {
                          value: _vm.form.categoryName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "categoryName", $$v)
                          },
                          expression: "form.categoryName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "类目级别",
                        prop: "categoryLevel",
                        rules: [
                          {
                            required: true,
                            message: "请选择类目级别",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _vm.isEdit
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.categoryLevelMap[_vm.form.categoryLevel]
                              )
                            ),
                          ])
                        : _c("v-select", {
                            attrs: { options: _vm.categoryLevelOps },
                            on: { change: _vm.changeLevel },
                            model: {
                              value: _vm.form.categoryLevel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "categoryLevel", $$v)
                              },
                              expression: "form.categoryLevel",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "类目编码",
                        prop: "categoryCode",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validCode,
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { maxlength: 10, placeholder: "请输入类目编码" },
                        model: {
                          value: _vm.form.categoryCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "categoryCode", $$v)
                          },
                          expression: "form.categoryCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.categoryLevel !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上级类目",
                            prop: "parentId",
                            rules: [
                              {
                                required: true,
                                message: "请选择上级类目",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _vm.isEdit
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.parentName || "--")),
                              ])
                            : _c("v-select", {
                                attrs: {
                                  filterable: "",
                                  options: _vm.prevCategoryOps,
                                },
                                model: {
                                  value: _vm.form.parentId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "parentId", $$v)
                                  },
                                  expression: "form.parentId",
                                },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("v-textarea", {
                        attrs: { maxlength: 200 },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }